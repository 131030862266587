import React, {useState} from 'react';
import logo from './img/logo.png';
import image1 from './img/image1.png';
import image2 from './img/image2.jpg';
import image3 from './img/image3.jpg';
import image4 from './img/image4.jpg';
import image5 from './img/image5.jpg';
import image6 from './img/image6.png';
import video from './video/main.mp4';

import {Add, Instagram, Menu, Close} from '@mui/icons-material';
import {Accordion, AccordionSummary, AccordionDetails, Button, Typography, TextField, Box} from '@mui/material';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './App.scss';

function App() {
  const [firstName, setFirstName] = useState('');
  const [email, setEmail] = useState('');
  const [description, setDescription] = useState('');
  const [mobileMenu, setMobileMenu] = useState(false);

  function handleSubmit(event) {
    event.preventDefault();
    console.log(firstName, email, description);

    fetch('/send_mail.php', {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({firstName, email, description})
    })
        .then(response => response.json())
        .then(data => {
          window.location.reload();
          console.log('Success:', data);
        })
        .catch((error) => {
          console.error('Error:', error);
        });
  }

  AOS.init({
    offset: 320,
    delay: 150, // values from 0 to 3000, with step 50ms
  });

  return (
    <div className="app">
      <header className="flex">

        <div className="logo-box">
            <a href="https://braintrainingcentre.ca/">
            <img src={logo} alt="braintrainingcentre logo"/>
            </a>
        </div>

        <div className="menu-box">
          <a href="#about">About</a>
          <a href="#servises">Services</a>
          <a href="#contact">Contact</a>
          <a className="button-1" href="https://braintrainingcentre.janeapp.com/" target="_blank" rel="noreferrer">Book an appointment</a>
        </div>

        <div className={"mobile-menu-box "+mobileMenu}>
          {mobileMenu ?
          <Close onClick={() => setMobileMenu(!mobileMenu)} sx={{ fontSize: 35 }}/>:
          <Menu onClick={() => setMobileMenu(!mobileMenu)} sx={{ fontSize: 35 }}/>}

          <div className="list">
            <a href="#about">About</a>
            <a href="#servises">Services</a>
            <a href="#contact">Contact</a>
          </div>
        </div>
      </header>

      <div className="video-box" data-aos="fade">
        <div className="sub-header">Unit 306 - 15252 32 Ave (3rd floor) South Surrey, BC, Canada, V3Z 0R7</div>
        <video src={video} autoPlay loop muted />

        <div className="h1-container">
          <div className="h1-box"
               data-aos="fade-up"
               data-aos-anchor-placement="top-bottom"
               data-aos-duration="1000"
          >
            <h1>Improving the Mind & Body Connection</h1>
            <p className="description-box">The Neuroptimal Brain Training Center in South Surrey offers <b>Neurofeedback Therapy</b>, a natural alternative to improve the general functioning of the brain by strengthening, calming & improving its stability & flexibility</p>
            <p className="buttons-row">
              <a href="https://braintrainingcentre.janeapp.com/" rel="noreferrer" className="button-1">Book an appointment</a>
              <a href="#about" className="button-2">Learn more</a>
            </p>
          </div>
        </div>
      </div>

      <div className="nfb-container cntr" id="about"  data-aos="fade-up"
           data-aos-anchor-placement="top-bottom"
           data-aos-duration="1000">
        <div className="nfb-box flex w50">
          <div className="left-block">
            <h2>Neurofeedback therapy may help with</h2>
            <div className="list">
              <p>ADHD / ADD</p>
              <p>Depression</p>
              <p>Panic Attacks</p>
              <p>Learning Difficulties</p>
              <p>Anxiety Disorders</p>
              <p>Stress</p>
              <p>Concussive Injuries</p>
              <p>Sleeping Problems</p>
              <p>Sleeping Problems</p>
              <p>OCD</p>
              <p>Explosiveness</p>
              <p>Tension Headaches</p>
              <p>Migraines</p>
              <p>Chronic Pain (some types of)</p>
            </div>
            <p className="buttons-row">
              <a href="https://braintrainingcentre.janeapp.com/" rel="noreferrer" className="button-1">Book an appointment</a>
            </p>
          </div>
          <div className="right-block">
            <p className="image-block"><img src={image1} alt="Neurofeedback"/></p>
            <p><b>Neurofeedback</b> (Neurogen High-Performance system) is a non-invasive technique that aims to improve brain function and alleviate symptoms related to various mental health conditions. It does not target specific brainwave frequencies but rather provides real-time feedback to the brain, allowing it to self-regulate and optimize its functioning. <br/>This process involves the use of sensors placed on the scalp to monitor brain activity while the individual engages in activities such as watching movies or listening to music. Through continuous feedback, the brain learns to recognize and correct patterns of dysregulation, leading to enhanced cognitive performance, emotional stability, and overall well-being.</p>
          </div>
        </div>
      </div>


      <div className="image-container cntr">
        <div className="image-box flex w33">
          <div  data-aos="fade-up"
                data-aos-anchor-placement="top-bottom"
                data-aos-duration="1000"
                data-aos-delay="150"
                className="block block-1">
            <div><img src={image2} alt="Brain Training and Focus"/></div>
            <div className="text-block">
              <h3>Brain Training and Focus</h3>
              <p>Enhance learning capacity, problem-solving and multitasking skills</p>
              <a href="#about" className="button-1">Read More</a>
            </div>
          </div>
          <div data-aos="fade-up"
               data-aos-anchor-placement="top-bottom"
               data-aos-duration="1000"
               data-aos-delay="450" className="block block-2">
            <div><img src={image3} alt="Brain Training and Sleep"/></div>
            <div className="text-block">
              <h3>Brain Training and Sleep</h3>
              <p>Optimize the quality of sleep to relax and recharge for the day ahead</p>
              <a href="#about" className="button-1">Read More</a>
            </div>
          </div>
          <div data-aos="fade-up"
               data-aos-anchor-placement="top-bottom"
               data-aos-duration="1000"
               data-aos-delay="750" className="block block-3">
            <div><img src={image4} alt="Brain Training and Sports"/></div>
            <div className="text-block">
              <h3>Brain Training and Sports</h3>
              <p>Align the body and mind to become more in tandem, working together step by step</p>
              <a href="#about" className="button-1">Read More</a>
            </div>
          </div>
        </div>
      </div>

      <div className="youtube-container cntr">
        <div className="flex w50">
          <div className="block-1">
            <article>
              <link rel="noreferrer" href="https://www.youtube.com" />
              <link rel="noreferrer" href="https://www.google.com" />
              <iframe
                  title="Neurofeedback video"
                  src="https://www.youtube.com/embed/SFXTfvAz8q4?iv_load_policy=3&rel=0&modestbranding=1&playsinline=1&autoplay=1"
                  allow="presentation; fullscreen; accelerometer; encrypted-media; gyroscope; picture-in-picture"
                  style={{
                    height: '100%',
                    width: '100%'
                  }}
              ></iframe>
            </article>
          </div>

          <div className="block-2" data-aos="fade-up"
               data-aos-anchor-placement="top-bottom"
               data-aos-duration="1000">
            <div className="title">About <b>NeurOptimal ®</b></div>
            <p>NeurOptimal® is the world's first and only Dynamical Neurofeedback™ brain training system designed to train the brain to function at peak performance levels</p>
            <a className="button-2" href="https://braintrainingcentre.janeapp.com/" target="_blank" rel="noreferrer">Book an appointment</a>
          </div>
        </div>
      </div>

      <div className="trainer-container cntr">
        <div className="flex">
          <div className="block-1">
            <div className="top-text">Meet your NeurOptimal® Trainer</div>
            <h3>Jenny Lower</h3>
            <p>As a neurofeedback practitioner, I provide gentle support and care for my clients, creating an atmosphere where people can fully relax, take time for themselves, and receive effective and safe sessions.</p>
            <p>I live in Vancouver for 10 years and work in health/wellness sector for over 5 years. I am passionate about holistic health and natural tools to improve your physical and mental wellbeing.</p>
          </div>

          <div className="block-2">
            <img src={image5} alt="Jenny Lower"/>
          </div>
        </div>
      </div>

      <div className="services-container cntr" id="servises">
        <div className="services-box flex w33">
          <div className="block block-1" data-aos="fade-up"
               data-aos-anchor-placement="top-bottom"
               data-aos-duration="500"
               data-aos-delay="150">
            <div className="top-text">Start</div>
            <h3>New Client</h3>
            <div className="list">
              <p>$49 - initial session</p>
              <p>15 minute introduction consultation</p>
              <p>33 minute neurofeedback session</p>
            </div>
            <p className="buttons-row">
              <a href="https://braintrainingcentre.janeapp.com/" rel="noreferrer" className="button-1">Book an appointment</a>
            </p>
          </div >
          <div className="block block-2" data-aos="fade-up"
               data-aos-anchor-placement="top-bottom"
               data-aos-duration="500"
               data-aos-delay="450">
            <div className="top-text">Choose Plan</div>
            <h3>Current Patient</h3>
            <div className="list">
              <div>
                <p>$99 - single session</p>
                <div>Packages:</div>
                <div>$449 - 5 session <span className="small-text">($89 per 1 session)</span></div>
                <div>$799 - 10 session <span className="small-text">($80 per 1 session)</span></div>
              </div>
            </div>
            <p className="buttons-row">
              <a href="https://braintrainingcentre.janeapp.com/" rel="noreferrer" className="button-1">Book an appointment</a>
            </p>
          </div>
          <div className="block block-3" data-aos="fade-up"
               data-aos-anchor-placement="top-bottom"
               data-aos-duration="500"
               data-aos-delay="750">
            <div className="top-text">For Home Use</div>
            <h3>Rent</h3>
            <p className="sub-title">NeurOptimal® System</p>
            <div className="list">
              <p>We help to choose the best System</p>
              <p>Rent System: sigle / multiply</p>
              <p>Buy System</p>
              <p>Free single training session</p>
              <p>For home use only</p>
            </div>
            <p className="buttons-row">
              <a href="#contact" className="button-2">Contact to Learn More</a>
            </p>
          </div>
        </div>

        <div className="referral-box">
          <Accordion>
            <AccordionSummary>
              <Typography>Referral program</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>Reach us for more informarmation</Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary >
              <Typography>Cancellation policy</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>You won't be charged when canceling up to 24 hours before your appointment time. Otherwise, you will be charged 50% of the service price for late cancellation, and 100% for not showing up.</Typography>
            </AccordionDetails>
          </Accordion>
        </div>

        <div className="faq-box">
          <h2>FAQ</h2>

          <div className="accordion-box">
            <Accordion>
              <AccordionSummary expandIcon={<Add />}>
                <Typography>Will my insurance cover me for sessions?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>Insurance coverage for sessions is not currently available, but we are working on it. </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<Add />}>
                <Typography>Do I need anything to bring with me for brain session?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>We provide everything needed to ensure your brain sessions are comfortable, safe, and enjoyable.</Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<Add />}>
                <Typography>Is parking provided?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>Yes, there is an ample parking lot directly in front of the building</Typography>
              </AccordionDetails>
            </Accordion>
          </div>
          <p className="image-row"  data-aos="fade-up">
            <img src={image6} alt="Neurofeedback meditation"/>
          </p>
        </div>

        <div className="contact-box"  data-aos="zoom-in" id="contact">
          <h2>Contact</h2>
          <Box component="form" noValidate autoComplete="off">
            <div className="flex">
              <TextField id="form-name" fullWidth label="Name" variant="filled" onChange={e => setFirstName(e.target.value)}/>
              <TextField id="form-email" fullWidth label="Email" variant="filled" onChange={e => setEmail(e.target.value)}/>
            </div>
            <div className="description-row">
              <TextField id="form-description" multiline rows={6} fullWidth variant="filled" onChange={e => setDescription(e.target.value)}/>
            </div>

            <Button onClick={handleSubmit} fullWidth variant={"contained"}>Send</Button>
          </Box>

          <a className="instagram-link" href="https://www.instagram.com/braintraining_bc?igsh=MWM2Z3d5ejNwMnVmZw==" rel="noreferrer" target="_blank">
            <Instagram sx={{ fontSize: 35 }}/>
          </a>
        </div>
      </div>

      <div className="map-box">
        <iframe
            title="Neurofeedback Trainer map address"
            width="100%"
            height="400px"
            frameBorder="0"
            allowFullScreen
            allow="geolocation"
            src="//umap.openstreetmap.fr/en/map/untitled-map_1094353?scaleControl=false&miniMap=false&scrollWheelZoom=false&zoomControl=true&editMode=disabled&moreControl=true&searchControl=null&tilelayersControl=null&embedControl=null&datalayersControl=true&onLoadPanel=none&captionBar=false&captionMenus=true"></iframe>
      </div>

      <div className="footer-cntr cntr">
          <div className="address-box">
            <h4>Address</h4>
            <p>Unit 306 - 15252 32 Ave (3rd floor)South Surrey, BC, Canada, V3Z 0R7
            <br/>
              <span>(The entrance to the corporate cener is next to Life Labs)</span>
            </p>
            <h4>Working Hours</h4>
            <p>Mondays - Saturday</p>
            <p>9am - 7pm</p>

        </div>
      </div>


    </div>
  );
}

export default App;
